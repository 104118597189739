import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "AgentListToolsView"
};
import { ref } from "vue";
import AgentToolsInfo from "@/views/Agent/AgentList/AgentList-Tools/AgentTools-Info";
import AgentToolsChangeMoney from "@/views/Agent/AgentList/AgentList-Tools/AgentTools-ChangeMoney";
import Common_Router from "@/utils/Common/Common_Router";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentList-Tools',
  setup(__props, {
    expose
  }) {
    const LangLib = new LangCom('Agent', 'AgentListTools');
    //const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const UserInfo = ref({
      UserName: null,
      id: null
    });
    const actions = [{
      name: GetLangSetup('actions.1'),
      type: 1
    }, {
      name: GetLangSetup('actions.2'),
      type: 2
    }, {
      name: GetLangSetup('actions.3'),
      type: 3
    }, {
      name: GetLangSetup('actions.4'),
      type: 4
    }, {
      name: GetLangSetup('actions.5'),
      type: 5
    }, {
      name: GetLangSetup('actions.6'),
      type: 6
    }];
    const show = ref(false);
    const OpenToolsView = (id, user) => {
      UserInfo.value.id = id;
      UserInfo.value.UserName = user;
      show.value = true;
    };
    expose({
      OpenToolsView
    });
    const refsAgentTools = ref();
    const refsAgentToolsChangeMoney = ref();
    const onSelect = item => {
      show.value = false;
      switch (item.type) {
        case 1:
          refsAgentTools.value.OpenAgentToolsInfoView();
          break;
        case 2:
          refsAgentToolsChangeMoney.value.OpenAgentToolsMoneyView(1);
          break;
        case 3:
          refsAgentToolsChangeMoney.value.OpenAgentToolsMoneyView(2);
          break;
        case 4:
          Common_Router.push({
            name: 'Key.KeyList',
            params: {
              UserName: UserInfo.value.UserName
            }
          });
          break;
        case 5:
          Common_Router.push({
            name: 'Agent.AgentUserList',
            params: {
              UserName: UserInfo.value.UserName
            }
          });
          break;
        case 6:
          Common_Router.push({
            name: 'Agent.AgentLog',
            params: {
              UserName: UserInfo.value.UserName
            }
          });
          break;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        actions: actions,
        onSelect: onSelect
      }, null, 8, ["show"]), _createVNode(_unref(AgentToolsInfo), {
        ref_key: "refsAgentTools",
        ref: refsAgentTools,
        UserInfo: UserInfo.value
      }, null, 8, ["UserInfo"]), _createVNode(_unref(AgentToolsChangeMoney), {
        ref_key: "refsAgentToolsChangeMoney",
        ref: refsAgentToolsChangeMoney,
        UserInfo: UserInfo.value
      }, null, 8, ["UserInfo"])]);
    };
  }
};