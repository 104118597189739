import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "AgentToolsMoneyView"
};
import { ref } from "vue";
import Apis_Agent from "@/apis/Apis_Agent";
import Apis_User from "@/apis/Apis_User";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentTools-ChangeMoney',
  props: ['UserInfo'],
  setup(__props, {
    expose
  }) {
    const props = __props;
    const LangLib = new LangCom('Agent', 'AgentToolsChangeMoney');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const PostData = ref({
      changeType: '1',
      //[操作类型] 1 = 充值下级 2 = 回收下级
      money: 1,
      //变动的额度
      moneyType: '1',
      //[额度类型] 1 = (余额)注册 2 = (余额)充值
      agentId: -1,
      UserName: null
    });
    const show = ref(false);
    const OpenAgentToolsMoneyView = type => {
      show.value = true;
      PostData.value.agentId = props.UserInfo.id;
      PostData.value.UserName = props.UserInfo.UserName;
      PostData.value.changeType = type.toString();
      GetAgentMoney();
    };
    expose({
      OpenAgentToolsMoneyView
    });
    const AgentMoney = ref({
      money_reg: -1,
      money_rec: -1
    });
    const SelfMoney = ref({
      reg: -1,
      rec: -2
    });
    const GetAgentMoney = () => {
      Apis_Agent.getAgentData(PostData.value.agentId).then(res => {
        var _data$money_reg, _data$money_rec;
        if (!Apis_Agent.checkResCode(res)) {
          return;
        }
        let data = res.data || {};
        AgentMoney.value.money_reg = (_data$money_reg = data.money_reg) !== null && _data$money_reg !== void 0 ? _data$money_reg : -2;
        AgentMoney.value.money_rec = (_data$money_rec = data.money_rec) !== null && _data$money_rec !== void 0 ? _data$money_rec : -3;
      }).finally(() => {
        Apis_Agent.getSelfMoney().then(res => {
          if (!Apis_User.checkResCode(res)) {
            return;
          }
          SelfMoney.value.rec = (res === null || res === void 0 ? void 0 : res.money_rec) || '???';
          SelfMoney.value.reg = (res === null || res === void 0 ? void 0 : res.money_reg) || '???';
        });
      });
    };
    const GetAgentMoneyStr = () => {
      let obj = AgentMoney.value;
      let recLang = GetLangSetup('GetAgentMoneyStr.rec');
      let str = GetLangSetup('GetAgentMoneyStr.reg') + `${obj.money_reg} ${recLang}${obj.money_rec}`;
      return str;
    };
    const confirm = () => {
      let {
        agentId,
        changeType,
        money,
        moneyType
      } = PostData.value;
      console.log('confirm', PostData.value);
      Apis_Agent.changeMoney(agentId, changeType, money, moneyType);
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_stepper = _resolveComponent("van-stepper");
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_dialog = _resolveComponent("van-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_dialog, {
        show: show.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => show.value = $event),
        title: `User Id:${props.UserInfo.id}`,
        "show-cancel-button": "",
        onConfirm: confirm
      }, {
        default: _withCtx(() => [_createVNode(_component_van_form, null, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: PostData.value.UserName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => PostData.value.UserName = $event),
            label: GetLangTemplate('vanField.UserName'),
            readonly: ""
          }, null, 8, ["modelValue", "label"]), _createVNode(_component_van_field, {
            name: "stepper",
            label: GetLangTemplate('vanField.money')
          }, {
            input: _withCtx(() => [_createVNode(_component_van_stepper, {
              modelValue: PostData.value.money,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => PostData.value.money = $event),
              integer: "",
              min: "1",
              max: "5000",
              "input-width": "30"
            }, null, 8, ["modelValue"])]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_van_field, {
            name: "radio",
            label: GetLangTemplate('vanField.changeType.label')
          }, {
            input: _withCtx(() => [_createVNode(_component_van_radio_group, {
              modelValue: PostData.value.changeType,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => PostData.value.changeType = $event),
              direction: "horizontal"
            }, {
              default: _withCtx(() => [_createVNode(_component_van_radio, {
                name: "1"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanField.changeType.1')), 1)]),
                _: 1
              }), _createVNode(_component_van_radio, {
                name: "2"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanField.changeType.2')), 1)]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_van_field, {
            name: "radio",
            label: GetLangTemplate('vanField.moneyType.label')
          }, {
            input: _withCtx(() => [_createVNode(_component_van_radio_group, {
              modelValue: PostData.value.moneyType,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => PostData.value.moneyType = $event),
              direction: "horizontal"
            }, {
              default: _withCtx(() => [_createVNode(_component_van_radio, {
                name: "1"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanField.moneyType.1')), 1)]),
                _: 1
              }), _createVNode(_component_van_radio, {
                name: "2"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanField.moneyType.2')), 1)]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_van_tag, {
            type: "primary",
            style: {
              "margin": "10px"
            }
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanTag.GetAgentMoneyStr')) + ":" + _toDisplayString(GetAgentMoneyStr()), 1)]),
            _: 1
          }), _createVNode(_component_van_tag, {
            type: "success",
            style: {
              "margin": "0 10px"
            }
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanTag.reg')) + ":" + _toDisplayString(SelfMoney.value.reg) + " " + _toDisplayString(GetLangTemplate('vanTag.rec')) + ":" + _toDisplayString(SelfMoney.value.rec), 1)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["show", "title"])]);
    };
  }
};