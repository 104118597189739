import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "AgentToolsInfoView"
};
import { ref, toRaw } from "vue";
import Apis_Agent from "@/apis/Apis_Agent";
import Common_Obj from "@/utils/Common/Common_Obj";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentTools-Info',
  props: ['UserInfo'],
  setup(__props, {
    expose
  }) {
    const props = __props;
    const LangLib = new LangCom('Agent', 'AgentToolsInfo');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const show = ref(false);
    const OpenAgentToolsInfoView = () => {
      show.value = true;
      GetAgentInfo();
    };
    expose({
      OpenAgentToolsInfoView
    });
    const TextField = ['password', 'name', 'nickname', 'contact'];
    const FieldLabel = {
      username: GetLangSetup('FieldLabel.username'),
      password: GetLangSetup('FieldLabel.password'),
      leader: GetLangSetup('FieldLabel.leader'),
      name: GetLangSetup('FieldLabel.name'),
      nickname: GetLangSetup('FieldLabel.nickname'),
      contact: GetLangSetup('FieldLabel.contact'),
      money_rec: GetLangSetup('FieldLabel.username'),
      money_reg: GetLangSetup('FieldLabel.username'),
      login_time: GetLangSetup('FieldLabel.login_time'),
      reg_time: GetLangSetup('FieldLabel.reg_time'),
      status: GetLangSetup('FieldLabel.status')
    };
    const PostData = ref({});
    const Form = ref([{}]);
    const GetAgentInfoObj = ref({});
    const GetAgentInfo = () => {
      Apis_Agent.getAgentData(props.UserInfo.id).then(res => {
        if (!Apis_Agent.checkResCode(res)) {
          return;
        }
        let data = res.data || {};
        if (data) {
          data.status = data.status.toString();
        }
        GetAgentInfoObj.value = Common_Obj.DeepClone(data);
        PostData.value = Common_Obj.DeepClone(data);
        Form.value.length = 0;
        for (let dataKey in data) {
          if (['id', 'ip', 'status', 'download_max', 'version'].includes(dataKey)) {
            continue;
          }
          Form.value.push({
            field: dataKey,
            isRead: !TextField.includes(dataKey),
            label: FieldLabel[dataKey] || '???'
          });
        }
      });
    };
    const confirm = () => {
      let data = toRaw(PostData.value);
      let bool = Common_Obj.isEqual(GetAgentInfoObj.value, data);
      if (bool) {
        VantMsgLib.alert(GetLangSetup('confirm.tips'));
        return;
      }
      Apis_Agent.setAgentInfo(props.UserInfo.id, data);
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_dialog = _resolveComponent("van-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_dialog, {
        show: show.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => show.value = $event),
        title: `UserId:${props.UserInfo.id}`,
        "show-cancel-button": "",
        onConfirm: confirm
      }, {
        default: _withCtx(() => [_createVNode(_component_van_form, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Form.value, item => {
            return _openBlock(), _createBlock(_component_van_cell_group, {
              inset: "",
              key: item.field
            }, {
              default: _withCtx(() => [_createVNode(_component_van_field, {
                modelValue: PostData.value[item.field],
                "onUpdate:modelValue": $event => PostData.value[item.field] = $event,
                label: item.label,
                disabled: item.isRead
              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled"])]),
              _: 2
            }, 1024);
          }), 128)), _createVNode(_component_van_field, {
            name: "radio",
            label: GetLangTemplate('vanField.status.label')
          }, {
            input: _withCtx(() => [_createVNode(_component_van_radio_group, {
              modelValue: PostData.value.status,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => PostData.value.status = $event),
              direction: "horizontal"
            }, {
              default: _withCtx(() => [_createVNode(_component_van_radio, {
                name: "1"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanField.status.1')), 1)]),
                _: 1
              }), _createVNode(_component_van_radio, {
                name: "0"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanField.status.2')), 1)]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }, 8, ["label"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["show", "title"])]);
    };
  }
};