import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "AgentListView"
};
import Apis_Agent from "@/apis/Apis_Agent";
import TableXsr from "@/components/Table/Table-User";
import { onMounted, provide, ref } from "vue";
import AgentListMenu from "@/views/Agent/AgentList/AgentList-Menu";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import AgentListTools from "@/views/Agent/AgentList/AgentList-Tools";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentList',
  setup(__props) {
    const LangLib = new LangCom('Agent', 'AgentList');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    let AgentListArr = ref([]);
    const GetAgentList = () => {
      Apis_Agent.getAgentList(1).then(res => {
        if (!Apis_Agent.checkResCode(res)) {
          return;
        }
        let arr = res.data || [];
        AgentListArr.value.length = 0;
        for (let arrKey in arr) {
          let {
            username,
            id
          } = arr[arrKey];
          AgentListArr.value.push({
            username,
            id
          });
        }
        AgentListArr.value = arr;
      });
    };
    onMounted(() => {
      GetAgentList();
    });
    const refsAgentListMenu = ref();
    const refsAgentListTools = ref();
    provide('SearchIcon', true); //通知表格组件开启右上角放大镜图标(筛选功能)[Table-Header.Vue]
    const TableEvent = options => {
      // [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.点击了放大镜图标[筛选/搜索]
      console.log('TableEvent', options);
      switch (options.type) {
        case 1:
          refsAgentListMenu.value.show = true;
          break;
        case 2:
          {
            CacheData.SaveData('AgentList', options.data);
            VantMsgLib.alertSuccess();
            break;
          }
        case 3:
          refsAgentListTools.value.OpenToolsView(options.data.id, options.data.username);
          break;
        case 4:
          refsAgentListMenu.value.OpenFilterView();
          break;
      }
    };
    const AgentListMenuEvent = msg => {
      console.log('MenuEvent', msg);
      if (msg.type === 1) {
        var _msg$data;
        let arr = (_msg$data = msg.data) !== null && _msg$data !== void 0 ? _msg$data : [];
        let res = arr.map(item => {
          return {
            username: item.username,
            id: item.id
          };
        });
        AgentListArr.value = res;
        if (res.length <= 0) {
          VantMsgLib.alert(GetLangSetup('AgentListMenuEvent.length'));
        }
      }
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableXsr), {
        ViewData: _unref(AgentListArr),
        HeaderTitle: GetLangTemplate('HeaderTitle'),
        onTableEvent: TableEvent
      }, null, 8, ["ViewData", "HeaderTitle"]), _createVNode(_unref(AgentListMenu), {
        ref_key: "refsAgentListMenu",
        ref: refsAgentListMenu,
        AgentListArr: _unref(AgentListArr),
        onAgentListMenuEvent: AgentListMenuEvent
      }, null, 8, ["AgentListArr"]), _createVNode(_unref(AgentListTools), {
        ref_key: "refsAgentListTools",
        ref: refsAgentListTools
      }, null, 512)]);
    };
  }
};