import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "AgentListMenuView"
};
import { ref } from "vue";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Router from "@/utils/Common/Common_Router";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentList-Menu',
  props: ['AgentListArr'],
  emits: ['AgentListMenuEvent'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    const LangLib = new LangCom('Agent', 'AgentListMenu');
    // const GetLangTemplate = field => LangLib.GetTemplateLang(field)
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const show = ref(false);
    const actions = [{
      name: GetLangSetup('actions.1'),
      type: 1
    }, {
      name: GetLangSetup('actions.2'),
      type: 2
    }, {
      name: GetLangSetup('actions.3'),
      type: 3
    }, {
      name: GetLangSetup('actions.4'),
      type: 4
    }];
    const CacheField = 'AgentList';
    const refsTableSetList = ref();
    const refsTableFilter = ref();
    const onSelect = item => {
      switch (item.type) {
        case 1:
          refsTableSetList.value.OpenView();
          break;
        case 2:
          CacheData.SaveData(CacheField, []);
          VantMsgLib.alertSuccess();
          break;
        case 3:
          // refsTableFilter.value.OpenFilter();
          OpenFilterView();
          break;
        case 4:
          Common_Router.push({
            name: 'Agent.AddAgent'
          });
          break;
      }
    };
    /**
     * 向父组件传递消息
     * @param type [消息类型] 1.已筛选完成数据
     * @param data [对象]附带数据
     * @constructor
     */
    const SendMsg = (type, data = null) => {
      let res = {
        type,
        data
      };
      emits('AgentListMenuEvent', res);
    };

    //定义[输入框(文本)]类型的筛选器
    const FilterTextActions = [{
      name: GetLangSetup('FilterTextActions.username'),
      field: 'username',
      icon: 'friends-o'
    }, {
      name: GetLangSetup('FilterTextActions.name'),
      field: 'name',
      icon: 'label-o'
    }, {
      name: GetLangSetup('FilterTextActions.nickname'),
      field: 'nickname',
      icon: 'chat-o'
    }];
    //定义[日期选择器(时间)]类型的筛选器
    const FilterTimeConfig = {
      name: GetLangSetup('FilterTimeConfig.name'),
      field: 'reg_time',
      icon: 'exchange'
    };
    const OpenFilterView = () => {
      refsTableFilter.value.OpenFilter();
    };
    expose({
      show,
      OpenFilterView
    });
    return (_ctx, _cache) => {
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      const _component_TableSetList = _resolveComponent("TableSetList");
      const _component_TableFilter = _resolveComponent("TableFilter");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        actions: actions,
        "close-on-click-action": "",
        onSelect: onSelect
      }, null, 8, ["show"]), _createVNode(_component_TableSetList, {
        ref_key: "refsTableSetList",
        ref: refsTableSetList,
        field: CacheField
      }, null, 512), _createVNode(_component_TableFilter, {
        ref_key: "refsTableFilter",
        ref: refsTableFilter,
        TextFormActions: FilterTextActions,
        TimeFormConfig: FilterTimeConfig,
        FilterArr: props.AgentListArr,
        onUpdateFilterData: _cache[1] || (_cache[1] = arr => SendMsg(1, arr))
      }, null, 8, ["FilterArr"])]);
    };
  }
};